import $ from 'jquery';

$('.burger-button').click(function() {
    if ($(this).hasClass('expanded')) {
        $(this).removeClass('expanded');
        $(this).siblings('.burger-menu').hide();
    } else {
        $(this).addClass('expanded');
        $(this).siblings('.burger-menu').show();
    }
});

$('.has-sub-nav').each(function() {
    $(this).click(function() {
        if ($(this).hasClass('expanded')) {
            $(this).children('ul').hide();
            $(this).removeClass('expanded');
        } else {
            $('.has-sub-nav.expanded > ul').hide();
            $('.has-sub-nav.expanded').removeClass('expanded');
            $(this).children('ul').show();
            $(this).addClass('expanded');
        }
    })
});

$('li.has-main-sub-nav').each(function() {
    $(this).click(function() {
        if ($(this).hasClass('expanded')) {
            $(this).children('ul').hide();
            $(this).removeClass('expanded');
        } else {
            $('li.has-main-sub-nav.expanded > ul').hide();
            $('li.has-main-sub-nav.expanded').removeClass('expanded');
            $(this).children('ul').show();
            $(this).addClass('expanded');
        }
    })
});
