import $ from 'jquery';

$(function () {
    $(document).ajaxComplete(function(event, xhr, settings) {
        if (xhr.status === 401 && xhr.responseJSON.message) {
            var msg = xhr.responseJSON.message;
            if (msg === "Session timeout") {
                window.location.reload();
            }
        }
    });
});
