/**
 * namespace CookieBanner
 *
 * Generates and displays a cookie banner if
 * the user has not
 * already accepted it in the browser used.
 *
 */

/*
 * CookieConsent v2 DEMO config.
*/

// obtain cookieconsent plugin
import $ from "jquery";

psiac.CookieBanner = function(enable_google_analytics_tracking) {
    var cc = initCookieConsent();

    // run plugin with config object
    cc.run({
        autorun: true,
        delay: 0,
        current_lang: 'de',
        auto_language: false,
        autoclear_cookies: true,
        cookie_expiration: 365,
        theme_css: window.location.origin + '/build/css/cookieconsent.css',
        force_consent: false,

        /* New options from v2.4 (everything is optional) */
        // remove_cookie_tables: false              // default: false (if set to true, cookie table's html will not be generated)

        // cookie_domain: location.hostname,       	// default: current domain
        // cookie_path: "/",                        // default: root
        // cookie_same_site: "Lax",

        /* Manage existing <scripts> tags (check readme.md) */
        page_scripts: true,                         // default: false (by default don't manage existing script tags)

        /* Basic gui options */
        gui_options: {
            consent_modal: {
                layout: 'cloud',               // box(default),cloud,bar
                position: 'bottom center',     // bottom(default),top + left,right,center:=> examples: 'bottom' or 'top right'
                transition: 'slide'            // zoom(default),slide
            },
            settings_modal: {
                layout: 'box',                 // box(default),bar
                // position: 'left',            // right(default),left (available only if bar layout selected)
                transition: 'slide'            // zoom(default),slide
            }
        },

        /* End new options added in v2.4 */

        onAccept: function (cookie) {
            if (cookie['level'].includes('analytics') && enable_google_analytics_tracking == true ) {
                psiac.gtmScripts();
                setTimeout(psiac.gAnalytics(), 3000);
            }
        },

        onChange: function (cookie) {
            if (cookie['level'].includes('analytics') && enable_google_analytics_tracking == true) {
                psiac.gtmScripts();
                setTimeout(psiac.gAnalytics(), 3000);
            }
        },

        languages: {
            'de': {
                consent_modal: {
                    title: "Ihre Privatsphäre",
                    description: 'Indem Sie auf „Alle akzeptieren“ klicken, stimmen Sie zu, dass PSIAC Cookies auf Ihrem Gerät speichern und Informationen gemäß unserer <a href="' + window.location.origin + '/privacy-policy" target="_blank">AGB</a> ' + 'offenlegen kann.',
                    primary_btn: {
                        text: 'Alle akzeptieren',
                        role: 'accept_all'      //'accept_selected' or 'accept_all'
                    },
                    third_btn: {
                        text: 'Nur notwendige akzeptieren',
                        role: 'accept_necessary'       //'accept_necessary'
                    },
                    secondary_btn: {
                        text: 'Einstellungen verwalten',
                        role: 'settings'       //'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: '<div>Cookie-Einstellungen</div>',
                    save_settings_btn: "Aktuelle Auswahl speichern",
                    accept_all_btn: "Alle akzeptieren",
                    reject_all_btn: "Alle ablehnen",
                    close_btn_label: "Close",
                    cookie_table_headers: [
                        {col1: "Cookie-Name"},
                        {col2: "Dauer"},
                        {col3: "Beschreibung"}
                    ],
                    blocks: [
                        {
                            title: "Cookie-Nutzung",
                            description: 'Wenn Sie eine unserer Websites besuchen, können Informationen in Ihrem Browser gespeichert oder abgerufen werden, meist in Form von Cookies. Diese Informationen können sich auf Sie, Ihre Präferenzen oder Ihr Gerät beziehen und werden hauptsächlich verwendet, damit die Website so funktioniert, wie Sie es erwarten. Die Informationen identifizieren Sie normalerweise nicht direkt, können Ihnen jedoch ein personalisierteres Web-Erlebnis bieten. Da wir Ihr Recht auf Privatsphäre respektieren, können Sie einige Arten von Cookies nicht zulassen. Klicken Sie auf die verschiedenen Kategorieüberschriften, um mehr zu erfahren und Ihre Präferenzen zu verwalten. Bitte beachten Sie, dass das Blockieren einiger Arten von Cookies Ihre Erfahrung mit der Website und den von uns angebotenen Diensten beeinträchtigen kann.'
                        }, {
                            title: "Unbedingt notwendige Cookies",
                            description: 'Diese Cookies sind für die ordnungsgemäße Funktion unserer Website erforderlich und speichern keine personenbezogenen Informationen. Das Setzen dieser Cookies ist unerlässlich, obwohl Sie Ihrerseits in Ihrem Browser das Setzen auch diese Cookies blockieren können. Für eine reibungslose Funktionalität der Website können wir dann aber nicht garantieren.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true  //cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [
                                {
                                    col1: 'JSESSIONID',
                                    col2: '24 Stunden',
                                    col3: 'dient der Nutzererkennung',
                                },
                                {
                                    col1: '__VCAP_ID__',
                                    col2: '24 Stunden',
                                    col3: 'dient der Nutzererkennung',
                                },
                                {
                                    col1: 'cc_cookie',
                                    col2: '1 Jahre',
                                    col3: 'dient der Nutzererkennung',
                                }
                            ]
                        }, {
                            title: "Analyse- und Leistungs-Cookies",
                            description: 'Diese Cookies erlauben uns, die Zugriffe und den Traffic auf unserer Website zu messen und damit die Performance unserer Seite zu verbessern. Diese Informationen helfen uns zu sehen, welche Seiten am Häufigsten genutzt werden und wie sich die Nutzer auf der Website bewegen. Dies hilft uns, die Seite für Sie zu optimieren. Alle Informationen werden aggregiert und sind anonym. Wenn Sie diese Cookies ausschalten, ist es für uns nicht möglich, Ihre Daten für die o. g. Zwecke zu verwenden.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [
                                {
                                    col1: '_ga',
                                    col2: '1 Jahre',
                                    col3: 'dient der Nutzererkennung',
                                },
                                {
                                    col1: '_gid',
                                    col2: '24 Stunden',
                                    col3: 'dient der Nutzererkennung',
                                },
                                {
                                    col1: '_gat_UA-*',
                                    col2: '24 Stunden',
                                    col3: 'dient dem Erhalt des Sitzungsstatus',
                                }
                            ]
                        },{
                            title: "Cookies löschen und Voreinstellungen ändern",
                            description: 'Wenn Sie die Voreinstellungen ändern wollen, können Sie nach dem Login unter „Mein PSIAC“ auf den Button „Zurücksetzen der Cookies“ klicken. Alternativ können Sie auch über die Browserfunktion „Cookies löschen“ bereits gesetzte Cookies wieder löschen. Sie erhalten dann beim nochmaligen Aufrufen von www.psiac.de das Cookie-Banner erneut, um die Cookie-Einstellungen neu anzupassen.'
                        }
                    ]
                }
            }
        }
    });

}
