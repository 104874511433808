(() => {
    document.addEventListener('DOMContentLoaded', () => {
        const submitButtons = document.querySelectorAll('button[data-prevent-double-submit]');
        submitButtons.forEach((button, index) => {
            const form = button.closest('form');
            form.addEventListener('submit', () => {
                button.disabled = true;

                // 2 Second Rule: https://uxmovement.com/buttons/when-you-need-to-show-a-buttons-loading-state/
                window.setTimeout(() => {
                    button.classList.add('spinner');
                }, 2000);
            })
        });
    });
})();
