import $ from 'jquery';
import moment from 'moment';

/**
 * Comparison with the server time.
 */
$.get(CURRENT_SERVER_TIME_PATH, function (datetime) {
    moment.now = function () {
        return +new Date(datetime);
    }
});
