import $ from 'jquery';

/**
 * jQuery Plugin "filterEntityList".
 *
 * Applies to input field.
 * After entering a search term, unmatched elements are hidden.
 */
(function ($) {
    $.fn.filterEntityList = function() {
        var searchEntities = [],
            searchEntitiesContainer = [],
            entityList = null,
            noEntitiesMsg = null,
            minNumChars = 2,
            input = $(this);

        searchEntitiesContainer = [].slice.call(document.getElementsByClassName('letter-container'));
        entityList = document.getElementsByClassName('entity-list')[0];
        noEntitiesMsg = document.getElementById('no-entities-msg');

        var removeClass = function(el, className) {
            el.classList.remove(className);
        };

        var addClass = function(el, className) {
            el.classList.add(className);
        };

        var hideEl = function(el) {
            var $el = $(el);
            if ($el.is('td') && $el.hasClass('search-entity')) {
                addClass(el.parentElement, "nomatch");

                //Tabelle ausblenden, wenn keine Row mehr sichtbar ist
                if ($(el.parentElement).siblings(':visible').length == 0) {
                    $el.parents('.letter-container').addClass('nomatch');
                }
            } else {
                addClass(el, "nomatch");
            }
        };

        var showEl = function(el) {
            var $el = $(el);
            if ($el.is('td') && $el.hasClass('search-entity')) {
                removeClass(el.parentElement, "nomatch");

                //Tabelle einblenden, wenn eine Row sichtbar wird
                if ($el.parents('.letter-container:visible').length == 0) {
                    $el.parents('.letter-container:visible').removeClass('nomatch');
                }
            } else {
                removeClass(el, "nomatch");
            }
        };

        var isTermFound = function(el, term, searchWholeString) {
            if (term !== '' && term.length >= minNumChars) {
                var text = el.textContent.trim().toUpperCase();
                return (searchWholeString === false && text.indexOf(term) == 0) ||
                    (searchWholeString === true && text.indexOf(term) !== -1);
            }

            return true;
        };

        var searchAndHide = function(term) {
            if (term.length < minNumChars) {
                if (entityList.classList.contains('filtered')) {
                    setNotFiltered();
                }

                return;
            }

            addClass(entityList, 'filtered');
            term = term.trim().toUpperCase();

            var searchWholeString = false;
            if (term.substring(0, 1) === "*") {
                term = term.substring(1).trim();
                searchWholeString = true;
            }

            var i, j, el;
            for (i = 0; i < searchEntitiesContainer.length; i++) {
                searchEntities = [].slice.call(searchEntitiesContainer[i].getElementsByClassName('search-entity'));
                var hasMatch = false;
                for (j = 0; j < searchEntities.length; j++) {
                    el = searchEntities[j];
                    if (isTermFound(el, term, searchWholeString)) {
                        hasMatch = true;
                        showEl(el);
                    } else {
                        hideEl(el);
                    }
                }
                if (!hasMatch) {
                    hideEl(searchEntitiesContainer[i])
                } else {
                    showEl(searchEntitiesContainer[i])
                }
            }

            if (entityList.offsetHeight === 0) {
                removeClass(noEntitiesMsg, 'hidden')
            } else {
                addClass(noEntitiesMsg, 'hidden')
            }

            updateGridBorder();
        };

        var setNotFiltered = function () {
            removeClass(entityList, 'filtered');

            var i, j;
            for (i = 0; i < searchEntitiesContainer.length; i++) {
                showEl(searchEntitiesContainer[i]);
                searchEntities = [].slice.call(searchEntitiesContainer[i].getElementsByClassName('search-entity'));

                for (j = 0; j < searchEntities.length; j++) {
                    showEl(searchEntities[j])
                }
            }
        };

        var updateGridBorder = function() {
            if (!hasGridProperty()) return;
            
            var i, j;
            for (i = 0; i < searchEntitiesContainer.length; i++) {
                searchEntities = [].slice.call(searchEntitiesContainer[i].getElementsByClassName('search-entity'));

                removeGridBorderClasses(searchEntities);

                var visibleSearchEntities = searchEntities.filter(function(searchEntity) {
                    return !searchEntity.classList.contains('nomatch');
                });

                for (j = 0; j < visibleSearchEntities.length; j++) {
                    if (j === 1 || (j % 3) === 1) {
                        addClass(visibleSearchEntities[j], 'border-around');
                    }
                    if (j < 3) {
                        addClass(visibleSearchEntities[j], 'pt-xs');
                    }
                }
            }
        };

        var removeGridBorderClasses = function(searchEntities) {
            for (var i = 0; i < searchEntities.length; i++) {
                removeClass(searchEntities[i], 'border-around');
                removeClass(searchEntities[i], 'pt-xs');
            }
        };
        
        var hasGridProperty = function() {
            return getComputedStyle(searchEntitiesContainer[0]).getPropertyValue('display') === "grid";
        };

        //ohne Timeout
        input.keyup( function() {
            searchAndHide(input.val());
        });

        // ZU Beginn Suche direkt ausfuehren (falls Suchbegriff durch den Browser Cache vorhanden ist).
        if (input.val() !== '') {
            searchAndHide(input.val());
        }

        //init
        updateGridBorder();
    };
}($));
