import $ from 'jquery';
import 'jquery-sticky';

/**
 * Initiates the Sticky Nav Bar.
 */
(function($) {
    var stickyNavBar = $('.sticky-nav-bar');
    if (stickyNavBar.length) {
        stickyNavBar.sticky({
            topSpacing: 0,
            zIndex: 1000,
            center: true,
            getWidthFrom: '.page-wrapper'
        });
    }
}($));
