import $ from 'jquery';

psiac.checkCapabilities = () => {
    $('#platform').html(navigator.appVersion);

    $('#javascript').show().html( 'JavaScript: <b>Eingeschaltet</b>' );

    var cookieEnabled = ( navigator.cookieEnabled ) ? true : false;
    if( typeof navigator.cookieEnabled == "undefined" && !cookieEnabled ) {
        document.cookie = "testcookie";
        cookieEnabled = ( document.cookie.indexOf( "testcookie" ) != -1 ) ? true : false;
    }
    var cookieState = cookieEnabled ? "Eingeschaltet" : "Ausgeschaltet";
    $('#cookies').html('Cookies: <b>' + cookieState + '</b>');

    $( '#ajax' ).html( 'Bitte warten...' );

    $.getJSON( "client-ajax-test", function(data) {
        $( '#ajax' ).html( data.result == 'success' ? 'Erfolgreich' : 'Fehlgeschlagen' );
        if( data.result != 'success' ) {
            $('#error-message').show().html( APP_TITLE + ' setzt das Funktionieren von AJAX-Anfragen voraus. Bei Ihrem System schlug die Test-Anfrage fehl. Dies liegt häufig an Proxy-Servern die die Anfragen auf Anwendungsebene verändern. Bitte erkundigen Sie sich bei Ihrer lokalen Netzadministration darüber. Möglicherweise kann ' + APP_TITLE + ' auf eine Whitelist gesetzt werden.' );
        }
    });
};
