// http://www.w3resource.com/javascript-exercises/javascript-string-exercise-11.php (in comment)
String.prototype.toCamelCase = function(){
    var str = this;

    var camel = str.replace(/_/g, ' ').replace(/\w\S*/g, function(str){
        return str.charAt(0).toUpperCase() + str.substr(1);
    });

    camel = camel.charAt(0).toLowerCase() + camel.substr(1);

    return camel.replace(/\s+/g,'');
};
