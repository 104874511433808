import $ from 'jquery';
psiac.exportToPDF = (current, pdfReportAuthors, currentDate, pdfReportCopyright, pdfReportFooterText, appTitle) => {
    $(".interaction-contact-list-display").attr("data-html2canvas-ignore","true");
    $(".page-context-menu").attr("data-html2canvas-ignore","true");
    let htmlContent = psiac.getHTMLContent(current, pdfReportAuthors, currentDate, pdfReportCopyright, pdfReportFooterText, appTitle);
    let tabTitle = $(".tabs .active").text() ? $(".tabs .active").text() : $("#"+current.id+"-data h3").text();
    let pdfReportTitle = tabTitle ? appTitle + ' - ' + tabTitle : appTitle;
    let styleElement = '<style type="text/css">' +
                            '#pdf-report-border .expander > .title > span:nth-child(3) {margin-left: auto !important;}' +
                            '.expander-button-container {box-sizing: initial;padding-left: 20px !important;width: 100%;display: table-cell !important;' +
                            '.expander-button {display: table-cell !important;padding-right: 12px !important;}' +
                        '</style>';
    let pdfContent = '<html><head>' + htmlContent.headContent + '</head>' +
            '<body><div style="text-align: center">' + pdfReportTitle + styleElement + '</div>' +
            '<div style="margin-left: 60px; border: 2px solid grey; width: 980px;padding: 4px;"id="pdf-report-border">' + htmlContent.divContents +
            '<div style="padding-left: 15px" id="pdf-report-footer" class="footer-copyright">' + htmlContent.footerContent +
            '</div></div></body></html>';
    let $selector = $(".tabs-content .active").length ? $(".tabs-content .active") : $("#"+current.id+"-data");
    $selector.prepend("<div class=\"pdf-spinner\"></div>");
    $("body").css("opacity","0.5");
    var opt = {
            margin:       [20,0,10,0],
            filename:     document.title  + '.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 1.5, letterRendering: true, scrollY: 0},
            jsPDF:        { unit: 'pt', format: 'a3', orientation: 'portrait' },
            enableLinks: true,
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

    html2pdf(pdfContent, opt).then(function () {
        $(".pdf-spinner").remove();
        $("body").css("opacity","unset");
    });
    $(".interaction-contact-list-display").removeAttr("data-html2canvas-ignore");
    $(".page-context-menu").removeAttr("data-html2canvas-ignore");
};
