import $ from 'jquery';
import validateTabs from '../editorfrontend/form_validate_hidden_tabs';

psiac.initTabs = (el, shouldTabsValidate) => {
    const $tabBox = $(el);

    var switchTab = function() {
        var el = $(this);
        if (!el.parent().hasClass('active')) {
            var tabs = el.parents('.tabs');
            el.parent().siblings().removeClass('active');
            el.parent().addClass('active');
            var tabSections = tabs.next().children();
            tabSections.removeClass('active');
            tabSections.filter('[tab-section="' + el.attr('tab-section') +'"]').addClass('active');
        }
    };

    var tabButtons = $tabBox.find('.tab-title').children('a');
    tabButtons.click(switchTab);

    if (shouldTabsValidate) validateTabs(tabButtons);
};

$(function() {
    psiac.initTabs('.tab-box', true);
});
