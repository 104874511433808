import $ from 'jquery';

/**
 * Module list_item_add_remove
 * Maintains a list of entities added by the input_autocomplete module.
 */
export default function(input, container) {
    const $input = $(input);
    const $container = $(container);
    const prototype = $container.attr("data-prototype");
    let num = $container.find('li').length;

    $input.on('addEntity', function(event, entityName) {
        var name = num++;
        var html = prototype;
        html = html.replace(/__entity_name__/g, entityName);
        html = html.replace(/__name__/g, name);
        var el = $(html);
        el.appendTo($container);
        el.find('input').eq(0).attr('value', entityName);
        $input.val('');
    });

    $("body").on("click", ".remove-item-btn", function() {
        $(this).closest('li').remove();
    });
};

