import $ from 'jquery';

psiac.resetInteractionGrid = (el) => {
    const $button = $(el);
    let $gridId = $("#grid-id");
    let gridId = $gridId.val();
    let gridName = $button.val();
    var reset = gridId + "_reset()";
    let url = "/interaction-versions/reset";
    $.ajax({
        type: "POST",
        url: url,
        data: {
            'gridName': gridName,
        },
        dataType: "json",
        success: function(response) {
            eval(reset);
        },
        error: function(response) {
            eval(reset);
        }
    });
    return false;
};

