copyInteractionRef = (id) => {
    const selection = window.getSelection();
    const range = document.createRange();
    /* Get the text field */
    range.selectNode(document.getElementById(id));
    selection.removeAllRanges();
    selection.addRange(range);

    document.getElementById("custom-tooltip-" + id).style.display = "inline";

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    setTimeout( function() {
        document.getElementById("custom-tooltip-" + id).style.display = "none";
    }, 2000);
}
