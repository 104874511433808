import $ from 'jquery';
import 'jquery-sticky';

/**
 * Initiates the filtering of lists.
 */
(function($) {
    $(function() {
        var filterListInput = $('#listFilterInput');
        if (filterListInput.length) {
            filterListInput.filterEntityList();
        }
    });
}($));
