import $ from 'jquery';
import Pikaday from 'pikaday';

/**
 * Register function out of modal ajax context.
 */
psiac.initDatepicker = () => {
    $.fn.initDatepicker();
};

(function ($) {
    // Determine if this browser supports the date input type.
    var dateSupported = function () {
        var el = document.createElement('input'),
            invalidVal = 'foo'; // Any value that is not a date
        el.setAttribute('type','date');
        el.setAttribute('value', invalidVal);
        // A supported browser will modify this if it is a true date field.
        return el.value !== invalidVal;
    };
    var i18n = {
        previousMonth : 'Vormonat',
        nextMonth     : 'Nächster Monat',
        months        : ['Januar','Februar', 'März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
        weekdays      : ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
        weekdaysShort : ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
    };
    $.fn.initDatepicker = function () {
        if (!dateSupported()) {
            var dateFields = document.querySelectorAll('input[type=date]');

            for (var i = 0; i < dateFields.length; i++) {
                var maxDate = dateFields[i].getAttribute("max");
                var minDate = dateFields[i].getAttribute("min");
                new Pikaday({
                    field: dateFields[i],
                    format: "YYYY-MM-DD",
                    changeMonth: true,
                    changeYear: true,
                    i18n: i18n,
                    firstDay: 1,
                    maxDate: maxDate ? new Date(maxDate) : null,
                    minDate: minDate ? new Date(minDate) : null
                });
            }
        }
    };
    $.fn.initDatepicker();
}($));
