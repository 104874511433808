import $ from 'jquery';
import autocomplete from '../modules/autocomplete/input_autocomplete';
import spinner from '../modules/input_spinner';
import itemAddRemove from '../modules/list_item_add_remove';

psiac.actingEntityAutocomplete = (input, autocompleteUrl, container, filter) => {
    const $input = $(input);
    let onSelectCb = null;

    spinner(input);

    if (container) {
        itemAddRemove(input, container);
        onSelectCb = (e, value) => {
            $(input).trigger('addEntity', [value])
        };
    }

    const spinnerCb = (el, state) => {
        $(el).trigger("toggleSpinner", [state])
    };

    const renderItemCallback = (item, search) => {
        if (filter) {
            if (filter(item)) {
                return '<span></span>';
            }
        }
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
        return '<div class="autocomplete-suggestion" data-val="' + item + '">' + item.replace(re, "<b>$1</b>") + '</div>';
    };

    $input.on('focusin', () => {
        autocomplete(
            input,
            autocompleteUrl,
            onSelectCb,
            spinnerCb,
            renderItemCallback
        );
    });

    $input.on('focusout', () => $input.autoComplete('destroy'));
};
