import $ from 'jquery';

$(function () {
    let currentSlide = 0;
    let radioButtonsSlides = $('input[name="carousels"]');

    setInterval(() => {
        if(radioButtonsSlides.length > 0) {
            next();
        }
    }, 5000);

    const next = () => {
        currentSlide >= radioButtonsSlides.length - 1 ? currentSlide = 0 : currentSlide++
        let currentRadio = radioButtonsSlides[currentSlide];
        currentRadio.click();
        $(".carousel-menu label[id='carousel-dot-" + (currentSlide + 1) + "']").css("background", "#444d56");
        $(".carousel-menu label[id!='carousel-dot-" + (currentSlide + 1) + "']").css("background", "#c6cbd1");
    }

    const prev = () => {
        currentSlide <= 0 ? currentSlide = radioButtonsSlides.length - 1 : currentSlide--
        let currentRadio = radioButtonsSlides[currentSlide];
        currentRadio.click();
        $(".carousel-menu label[id='carousel-dot-" + (currentSlide + 1) + "']").css("background", "#444d56");
        $(".carousel-menu label[id!='carousel-dot-" + (currentSlide + 1) + "']").css("background", "#c6cbd1");
    }

    const nextSelector = document.querySelector(".next");
    const prevSelector = document.querySelector(".prev");

    if(nextSelector) {
        nextSelector.addEventListener('click', next);
    }

    if(prevSelector) {
        prevSelector.addEventListener('click', prev);
    }
});
