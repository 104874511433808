scrollToTop = (selector) => {
    selector.style.display = 'none';
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

window.onscroll = () => {
    if (document.documentElement.scrollTop > 100) {
        $(".scroll-to-top").css("display","block");
        $(".scroll-to-top").delay(4000).fadeOut();
    } else {
        $(".scroll-to-top").css("display","none");
    }
}

scrollToBottom = (selector) => {
    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
    });
}
