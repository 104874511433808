import $ from 'jquery';

(function($) {
    $("[data-reveal-ajax]").click(function (e) {
        e.preventDefault();
        var $el = $(this);
        var url = $el.attr('href');
        if (url.length === 0) throw "no url provided";
        $.get(url, null, function(res) {
            var modal = new psiac.Modal(res);
            modal.reveal();
        });
    });
}($));

psiac.showPriceIncreaseModalPopUp = (url) => {
    $.get(url, null, function(res) {
        if(res.length > 2) {
            var modal = new psiac.Modal(res);

            $("#btnClosePopup").bind('click', () => {
                modal.hide();
            });

            modal.reveal();
        }
    });
};
