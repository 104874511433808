import $ from 'jquery';

psiac.toggleExpander = (el) => {
    const $title = $(el);
    const $expander = $title.parent();
    $title.next().slideToggle(150);
    $expander.toggleClass('expanded');

    const url = $expander.data('url');
    if (url) {
        const $content = $title.siblings('.inner');
        // Only load content if it has not been loaded yet.
        if ($content.has('.spinner').length === 1) {
            $.get(url)
                .then(function (res) {
                    $content.html(res);
                })
                .fail(function () {
                    $content.html(
                        '<div class="error">Der Inhalt konnte nicht geladen werden, laden Sie bitte die Seite neu und versuchen es nochmal.</div>'
                    );
                })
            ;
        }
    }
};
