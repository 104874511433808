import $ from 'jquery';
import 'jquery-form';
import showConfirmModal from '../modules/show_confirm_modal';

psiac.referenceCollection = (el, options = {}) => {
    $(el).referenceCollection(options);
};

// https://github.com/jquery-boilerplate/jquery-boilerplate/blob/master/src/jquery.boilerplate.js
(function($, window, document, undefined) {
    "use strict";

    var pluginName = "referenceCollection",
        defaults = {
            createReferenceAjaxUrl: undefined
        };

    // The actual plugin constructor
    function Plugin (element, options) {
        this.element = element;
        this.settings = $.extend({}, defaults, options);
        this._defaults = defaults;
        this._name = pluginName;
        this.addReferenceModal = undefined;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function() {
            this.$referencesContainer = $(this.element).find('.references-container');
            this.prototype = this.$referencesContainer.attr('data-prototype');
            this.count = this.getCount();
            this._addListeners();
            this._setAjaxUrlsFromDataAtttributes();
            this._assertSettingsExist();
        },

        /**
         * Reads URLs from the data attributes if they were not passed to the plugin.
         * @private
         */
        _setAjaxUrlsFromDataAtttributes: function () {
            var $el = $(this.element);
            try { this._assertSettingExists('createReferenceAjaxUrl') }
            catch (e) { this.settings.createReferenceAjaxUrl = $el.find('.create-reference').attr('data-create-url'); }
        },

        /**
         * Make sure that the required URLs have been transferred.
         * @private
         */
        _assertSettingsExist: function () {
            this._assertSettingExists('createReferenceAjaxUrl');
        },

        _assertSettingExists: function (name) {
            if (typeof this.settings[name] === 'undefined') {
                throw name + " variable must be defined";
            }
        },

        _addListeners: function () {
            var $el = $(this.element);

            // off() ensures that handler is only linked to the element once.
            var btnAdd = $el.find('.create-reference');
            var btnRemove = $el.find('.remove-reference');
            btnAdd.off('click').on('click', $.proxy(this._createReferenceClicked, this));
            btnRemove.off('click').on('click', $.proxy(this._removeReferenceClicked, this));
        },

        getCount: function () {
            return $(this.element).find('.reference-container').length;
        },

        /**
         * Remove Reference click callback.
         * Removes a source from the DOM.
         *
         * @param e
         */
        _removeReferenceClicked: function (e) {
            e.preventDefault();
            // TODO mm 2017-02-14: Bessere Lösung als 2x div nach oben
            // TODO dr jetzt 3x :-)
            var container = $(e.target).closest('.reference-container').parent().parent().parent().parent();
            var text = $(e.target).parent().prev().html();

            showConfirmModal(() => container.remove(), () => {}, {
                "title": "Literaturangabe entfernen?",
                "content": text + "<br>Möchten Sie den Verweis auf diese Literaturangabe entfernen? Damit wird lediglich der Verweis entfernt, die Literaturangabe selbst bleibt bestehen.",
                "confirm_button": "Entfernen",
                "confirm_button_class": "button red right",
            });
        },

        /**
         * Create Reference click callback.
         * Opens the modal dialog to create and then insert a new source.
         * @param e
         */
        _createReferenceClicked: function (e) {
            e.preventDefault();
            var me = this;
            $.get(this.settings.createReferenceAjaxUrl, null, function (res) {
                var modal = new psiac.Modal(res, {css: {paddingTop: 50}});
                modal.reveal();
                me.addReferenceModal = modal;

                me._onCreateReferenceModalLoaded();
            });
        },

        /**
         * Inserts a source consisting of ID and text in the DOM.
         * @param id
         * @param text
         */
        insertReference: function (id, text) {
            var prototype = this.prototype.replace(/__name__/g, this.count++).replace(/__id__/g, id);
            var $newReference = $(prototype);
            $newReference.find('.reference-display-text').html(text);
            $newReference.find('.reference-container input').val(id);
            this.$referencesContainer.append($newReference);
            $newReference.addCommentToggleHandler();
            $newReference.on('click', '.remove-reference', $.proxy(this._removeReferenceClicked, this));
        },

        /**
         * Will be executed as soon as the content of the CreateReference (create new reference) modal has been loaded.
         * @private
         */
        _onCreateReferenceModalLoaded: function () {
            this._makeAjaxSubmitForm();
        },

        _makeAjaxSubmitForm: function () {
            var self = this;
            this.addReferenceModal.$modal.ajaxForm(function (res, type, xhr) {
                // If JSON is returned, the request was successful.
                if (xhr.getResponseHeader('Content-Type') === 'application/json') {
                    self.insertReference(res.data.id, res.data.text);
                    self.addReferenceModal.hide();
                }
                // The form was successfully submitted,
                // the submitted entries match, however, a bibliography that has already been saved.
                // A final confirmation with an overview of the references to be reused is displayed.
                else if ($(res).is('#reference-to-reuse')) {
                    self._insertReferenceToReuseConfirmation(res);
                }
                // In the event of errors, the old form is replaced by the new one.
                else {
                    // Simple HTML replace the form content.
                    // Since the entire form is not replaced, the event listeners do not have to be reset.
                    var $currentFormField = self.addReferenceModal.$modal.find('section.active form');
                    var $newFormField = $(res).find('section.active form');
                    $currentFormField.html($newFormField.html());

                    psiac.editAndCreateReference();
                    psiac.resetReferencesForm();
                }
            });
        },

        /**
         * Creating a bibliography failed, * but another information was found that can be reused. * -> accept references * -> cancel
         *
         * @param html
         * @private
         */
        _insertReferenceToReuseConfirmation: function (html) {
            var self = this;
            self.addReferenceModal.update($(html));

            // Scroll to the beginning of the modal, otherwise it could be out of view.
            $("html, body").animate({ scrollTop: self.addReferenceModal.$modal.offset().top }, "slow");

            // Bibliography is adopted.
            self.addReferenceModal.$modal.find('#apply-insert-reference').click(function (e) {
                e.preventDefault();
                var $el  = self.addReferenceModal.$modal.find('#insert-reference'),
                    id   = $el.attr('data-id'),
                    text = $el.html();

                self.addReferenceModal.hide();
                self.insertReference(id, text);
            });

            // cancel -> close modal.
            self.addReferenceModal.$modal.find('#cancel-insert-reference').click(function (e) {
                e.preventDefault();
                self.addReferenceModal.hide();
            });
        }
    });

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function(options) {
        return this.each(function() {
            if (!$.data( this, "plugin_" + pluginName)) {
                $.data(this, "plugin_" + pluginName, new Plugin(this, options));
            }
        });
    };
})($, window, document);
