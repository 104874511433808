import $ from 'jquery';

psiac.Modal = function Modal(content, options) {
    var me = this;
    this.settings = $.extend({}, this.settings, options);
    this.$closeBtn = $('<a class="close-reveal-modal" aria-label="Close">&#215;</a>').click($.proxy(me.hide, me));
    this.$bg = this.__createBackground();
    this.$modal = this.__createModal(content);
};

psiac.Modal.prototype = {
    settings: {
        modalClass: "modal-dialog",
        backgroundClass: "modal-dialog-bg",
        fadeInClass: "fadeIn",
        fadeOutClass: "fadeOut",
        hideable: true,
        marginTop: 30,
        css: {}
    },

    __createBackground: function() {
        var me = this;
        return $('<div></div>')
            .addClass(this.settings.backgroundClass)
            .appendTo($('body'))
            .click(this.settings.hideable ? $.proxy(me.hide, me): () => {});
    },

    __createModal: function (content) {
        return $('<div></div>')
            .addClass(this.settings.modalClass)
            .appendTo($('body'))
            .html(content)
            .append(this.settings.hideable ? this.$closeBtn : null)
            .css(this.settings.css)
    },

    reveal: function() {
        this.$bg.addClass(this.settings.fadeInClass);
        var scrollTop = window.scrollY || document.documentElement.scrollTop;
        var innerHeight = window.innerHeight;
        var elHeight = this.$modal.height();
        var pos = scrollTop;

        if (elHeight > innerHeight / 2) {
            pos = scrollTop + this.settings.marginTop;
        } else {
            pos = innerHeight / 2 - elHeight + scrollTop;
        }

        this.$modal.css({
            top: pos,
            display: "block"
        });
        this.$modal.addClass(this.settings.fadeInClass);
    },

    hide: function() {
        var me = this;

        //IE9 Polyfill
        if (this.$modal.get(0).style.animationName === undefined) {
            me.$bg.remove();
            me.$modal.remove();
        } else {
            this.$modal.addClass(this.settings.fadeOutClass);
            this.$bg.addClass(this.settings.fadeOutClass);
            this.$bg.bind('oanimationend animationend webkitAnimationEnd', function() {
                me.$bg.remove();
            });
            this.$modal.bind('oanimationend animationend webkitAnimationEnd', function() {
                me.$modal.remove();
            });
        }
    },

    update: function(content) {
        this.$modal.html(content).append(this.$closeBtn.click($.proxy(this.hide, this)));
    }
};
